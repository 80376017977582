export const pricingFormLabels = {
    'en':{
        firstname: 'Your first name',
        lastname: 'Your last name',
        email: 'Your email address',
        phone: 'Your phone number',
        property_address: 'Property address',
        number_of_bedrooms: 'No. of bedrooms:',
        of_properties: 'No. of properties in your portfolio:',
        is_your_property_available_365_days_a_year_: 'Your property is available 365 days a year.',
        privacy: 'By submitting this form, you accept our <a href="/privacy-policy">Privacy Policy</a>',
        results: 'Get my results',
    },
    'fr': {
        firstname: 'Votre prénom',
        lastname: 'Votre nom de famille',
        email: 'Votre adresse e-mail',
        phone: 'Votre numéro de téléphone',
        property_address: 'Adresse de la propriété',
        number_of_bedrooms: 'Nombre de chambres :',
        of_properties: 'Nombre de propriétés dans votre portefeuille:',
        is_your_property_available_365_days_a_year_: 'Votre propriété est disponible 365 jours par an.',
        privacy: "En soumettant ce formulaire, vous acceptez notre <a href='/privacy-policy'>Politique de confidentialité</a>",
        results: 'Obtenir mes résultats',
    },
    'es': {
        firstname: 'Tu nombre',
        lastname: 'Tu apellido',
        email: 'Tu dirección de correo electrónico',
        phone: 'Tu número de teléfono',
        property_address: 'Dirección de la propiedad',
        number_of_bedrooms: 'Número de habitaciones:',
        of_properties: 'Número de propiedades en tu portafolio:',
        is_your_property_available_365_days_a_year_: 'Tu propiedad está disponible los 365 días del año.',
        privacy: 'Al enviar este formulario, aceptas nuestra <a href="/privacy-policy">Política de privacidad</a>',
        results: 'Obtener mis resultados',
    }
}

export const pricingResultsLabels = {
    "fr": {
        "heading": "Vos résultats",
        "income": "Revenu potentiel par mois",
        "disclaimer": "Avertissement. Cette évaluation est basée sur nos calculs et, bien qu'elle vise à être aussi précise que possible, elle pourrait être supérieure ou inférieure à la valeur réelle.",
        "assumptionsHeading": "Nous avons fait les hypothèses suivantes à votre sujet:",
        "assumptions": [
            "L'aménagement et l'intérieur de votre propriété sont de meilleure qualité.",
            "La proximité des commodités, y compris les magasins et le métro, augmente le prix."
        ],
        "calendlyHeading": "Réservez un appel",
        "currency": "€",
    },
    "es": {
        "heading": "Tus resultados",
        "income": "Ingresos potenciales por mes",
        "disclaimer": "Advertencia. Esta valoración se basa en nuestros cálculos y, aunque se esfuerza por ser lo más precisa posible, podría ser más alta o más baja que el valor real.",
        "assumptionsHeading": "Hemos hecho las siguientes suposiciones sobre ti:",
        "assumptions": [
            "El mobiliario y el interior de tu propiedad están a un nivel superior.",
            "La proximidad de servicios, incluyendo tiendas y el metro, aumenta el precio."
        ],
        "calendlyHeading": "Reservar una llamada",
        "currency": "€",
    }
}